import { grey } from '@material-ui/core/colors';
import createTheme from '@material-ui/core/styles/createTheme';
import baseTheme from './base';


const lightTheme = createTheme({
    props: {

    },
    overrides: {

    },
    palette: {
        primary: {
            main: "#438eb9",
        },
        secondary: {
            main: '#995A00',
        },
        type: "light",
        background: {
            default: '#FAFAFA',
            level1: '#fff',
            level2: grey[100]
        },

    },

}, baseTheme)

export default lightTheme