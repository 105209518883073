import { observable, action, computed, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { AlarmEventDTO, DeviceEventDTO, ReportFormats, RuleEngineAlarmDTO } from '../../api';
import { Moment } from 'moment';
import moment from 'moment';
import saveAs from 'file-saver'
import i18n from '../../i18n';
import { QueryResult } from '@material-table/core';
class RuleEngineAlarmStore {
    rootStore: RootStore
    @observable isLoading = true
    @observable list: RuleEngineAlarmDTO[] = []
    @observable alarmEventList: AlarmEventDTO[] = []
    @observable deviceEventList: DeviceEventDTO[] = []
    @observable alarmStartDate: Moment | null = moment().add("day", -1).set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    @observable alarmEndDate: Moment | null = moment().add("day", 1).set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    @observable deviceStartDate: Moment | null = moment().add("day", -1).set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    @observable deviceEndDate: Moment | null = moment().add("day", 1).set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    @observable deviceEventCount: Number = 0
    @observable alarmEventCount: Number = 0
    @observable alarmCount: Number = 0
    @observable alarmDeviceFilter: string = ""
    @observable alarmDeviceModelFilter: string = ""
    @observable deviceDeviceFilter: string = ""
    @observable deviceDeviceModelFilter: string = ""
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }

    @computed get Suggestions() {
        return this.list.map(d => ({ label: d.name, value: d.id }))
    }

    @action getList = async () => {
        this.isLoading = true
        this.list = [];
        this.list = (await this.rootStore.api.alarmApi.getRuleEngineAlarms({}))
        this.isLoading = false
    }

    getAlarmEventList = async (query) => {
        let result = await this.rootStore.api.alarmApi.getAlarmEvents({
            deviceId: this.alarmDeviceFilter === "" ? undefined : this.alarmDeviceFilter,
            startDate: this.alarmStartDate === null ? undefined : this.alarmStartDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
            endDate: this.alarmEndDate === null ? undefined : this.alarmEndDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
            deviceModelId: this.alarmDeviceModelFilter === "" ? undefined : this.alarmDeviceModelFilter,
            pageNumber: query.page + 1,
            pageSize: query.pageSize
        })

        return {
            data: result.data,
            page: result.pageNumber! - 1,
            totalCount: result.totalRecords,
        } as QueryResult<AlarmEventDTO>
    }

    getDeviceEventList = async (query) => {


        let result = await this.rootStore.api.alarmApi.getDeviceEvents({
            deviceId: this.deviceDeviceFilter === "" ? undefined : this.deviceDeviceFilter,
            startDate: this.deviceStartDate === null ? undefined : this.deviceStartDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
            endDate: this.deviceEndDate === null ? undefined : this.deviceEndDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
            deviceModelId: this.deviceDeviceModelFilter === "" ? undefined : this.deviceDeviceModelFilter,
            pageNumber: query.page + 1,
            pageSize: query.pageSize
        })

        return {
            data: result.data,
            page: result.pageNumber! - 1,
            totalCount: result.totalRecords,
        } as QueryResult<DeviceEventDTO>
    }

    @action add = async (d: RuleEngineAlarmDTO) => {
        let newD = await this.rootStore.api.alarmApi.postRuleEngineAlarm({ ruleEngineAlarmPostDTO: d })
        this.list.unshift(newD)
    }

    @action update = async (d) => {
        await this.rootStore.api.alarmApi.putRuleEngineAlarm({ id: d.id!, ruleEngineAlarmUpdateDTO: d })
        if (true) {
            this.getList()
        }
    }

    @action delete = async (id: string) => {
        let a = this.list.find(t => t.id === id)
        //@ts-ignore
        this.list.remove(a)
        try {
            await this.rootStore.api.alarmApi.deleteRuleEngineAlarm({ id: id })
        } catch {
            this.getList()
        }
    }

    @action getDeviceEventCount = async () => {
        this.deviceEventCount = (await this.rootStore.api.alarmApi.getDeviceEventCount({}))
    }
    @action getAlarmEventCount = async () => {
        this.alarmEventCount = (await this.rootStore.api.alarmApi.getAlarmEventCount({}))
    }
    @action getAlarmCount = async () => {
        this.alarmCount = (await this.rootStore.api.alarmApi.getAlarmCount({}))
    }

    @action alarmEventTableReport(format: ReportFormats) {

        this.rootStore.api.reportApi.alarmEventTableReport({
            format: format,
            startDate: this.alarmStartDate === null ? undefined : this.alarmStartDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
            endDate: this.alarmEndDate === null ? undefined : this.alarmEndDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
        }).then((data) => {
            if (data) {
                saveAs(data, i18n.t("reports.tablo.alarmEvent") + " " + moment().format("LLL") + (format === ReportFormats.Pdf ? ".pdf" : ".xlsx"))
            }
        })
    }

    @action deviceEventTableReport(format: ReportFormats) {

        this.rootStore.api.reportApi.deviceEventTableReport({
            format: format,
            startDate: this.deviceStartDate === null ? undefined : this.deviceStartDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
            endDate: this.deviceEndDate === null ? undefined : this.deviceEndDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
        }).then((data) => {
            if (data) {
                saveAs(data, i18n.t("reports.tablo.deviceEvent") + " " + moment().format("LLL") + (format === ReportFormats.Pdf ? ".pdf" : ".xlsx"))
            }
        })
    }

    @action alarmTableReport(format: ReportFormats) {

        this.rootStore.api.reportApi.alarmTableReport({
            format: format
        }).then((data) => {
            if (data) {
                saveAs(data, i18n.t("reports.tablo.alarm") + " " + moment().format("LLL") + (format === ReportFormats.Pdf ? ".pdf" : ".xlsx"))
            }
        })
    }
}

export default RuleEngineAlarmStore