const dev = {
    root: '/',
    base_url: 'localhost:3000',
    // api_url: "https://fys.tnbmobil.com",
    api_url: "https://localhost:5001",
    signalr: "https://localhost:5031",
    file_url: "http://localhost:9040",
}
const _config = process.env.NODE_ENV === 'production' ?
    (

        {
            ...dev,
            base_url: 'https://iot.lemonsoft.com.tr',
            api_url: "https://api.iot.lemonsoft.com.tr",
            signalr: "https://signalr.iot.lemonsoft.com.tr",
            file_url: "https://mi.iot.lemonsoft.com.tr",

        }
    )
    :
    dev

export default {
    tokenKey: "uafsasfjwo",
    ..._config
}

