import { action, makeObservable } from 'mobx';
import RootStore from "../RootStore"

class TenantStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }

    @action uploadLogo = async (d: Blob) => {

        const obj = await this.rootStore.api.tenantApi.postTenantLogo({
            id: this.rootStore.authStore.tenant?.id!,
            file: d === null ? undefined : d
        })

        if (this.rootStore.authStore.tenant) {
            this.rootStore.authStore.tenant.logo = obj.logo
        }
    }
}
export default TenantStore