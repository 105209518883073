import { observable, action, computed, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { ReportFormats, RuleChainDTO, RuleChainPostDTO } from '../../api';
import moment from 'moment';
import saveAs from 'file-saver'
import i18n from '../../i18n';
class RuleChainStore {
    rootStore: RootStore
    @observable isLoading = true
    @observable list: RuleChainDTO[] = []

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }

    @computed get Suggestions() {
        return this.list.map(d => ({ label: d.name, value: d.id }))
    }


    @action getList = async () => {
        this.isLoading = true
        this.list = [];
        this.list = (await this.rootStore.api.ruleChainApi.getRuleChains({}))
        this.isLoading = false
    }

    @action add = async (d: RuleChainPostDTO) => {
        let newD = await this.rootStore.api.ruleChainApi.postRuleChain({ ruleChainPostDTO: d })
        this.list.unshift(newD)
    }
    /*
        @action update = async (d: TenantUpdateDTO) => {
            (await this.rootStore.api.ruleEngineApi.apiTenantIdPut({ id: d.id!, tenantUpdateDTO: d }))
            if (true) {
                this.getList()
            }
        }
    
        @action delete = async (id: string) => {
            let a = this.list.find(t => t.id === id)
            //@ts-ignore
            this.list.remove(a)
            try {
                await this.rootStore.api.ruleEngineApi.apiTenantIdDelete({ id: id })
            } catch {
                this.getList()
            }
        }
    */

    @action ruleChainTableReport(format: ReportFormats) {

        this.rootStore.api.reportApi.ruleChainTableReport({
            format: format
        }).then((data) => {
            if (data) {
                saveAs(data, i18n.t("reports.tablo.ruleChain") + " " + moment().format("LLL") + (format === ReportFormats.Pdf ? ".pdf" : ".xlsx"))
            }
        })
    }

}

export default RuleChainStore