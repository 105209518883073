import RootStore from "./stores/RootStore"
import { createRouterState, Route } from 'mobx-state-router'
import config from "./config"


// const checkData = async (dataStore, rootStore: RootStore) => {
//     if (rootStore.dataStore[dataStore] && rootStore.dataStore[dataStore].list.length === 0) {
//         await rootStore.dataStore[dataStore].getList()
//     }
// }

// const updateDf = async (dataStore, rootStore: RootStore, queryParams) => {
//     if (rootStore.dataStore[dataStore]) {
//         rootStore.dataStore[dataStore].dateFilter.updateFromJson(queryParams)
//     }
// }

const globalBeforeEnter = async (fromState, toState, rootStore: RootStore) => {
    try {
        if (!rootStore.authStore.auth) {
            //routerStore.rootStore.setRedirect(toState)
            return createRouterState('login')
        }
    }
    catch (error) {
        console.log("error From route", error)
        return createRouterState('login')
    }
}


export default [
    {
        name: "404",
        pattern: "404"
    },
    {
        name: 'externallogin',
        pattern: '/externalLogin',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            /*
            const { rootStore } = routerStore.option
            
            if (toState.queryParams && toState.queryParams.rentalOnly) {
                rootStore.user.fleetEntryType = toState.queryParams.rentalOnly === "true" ? FleetEntryTypeEnum.Rental : FleetEntryTypeEnum.Owned
            } else {
                rootStore.user.fleetEntryType = FleetEntryTypeEnum.Owned
            }
            if (toState.queryParams && toState.queryParams.token) {
                var res = await rootStore.authStore.externalLogin({ token: toState.queryParams.token, activeCustomer: toState.queryParams.activeCustomer })
                if (res) {
                    return Promise.reject(createRouterState('dashboard'))
                }
            }
            */
            return createRouterState('relogin')
        }
    },
    {
        name: 'relogin',
        pattern: '/relogin',
    },
    {
        name: 'login',
        pattern: '/',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {

            const { rootStore } = routerStore.options
            const oldToken = localStorage.getItem(config.tokenKey)

            if (oldToken) {
                rootStore.authStore.auth = true
                return createRouterState('dashboard')

            }
        },
        onEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const {
                rootStore
            } = routerStore.options
            if (rootStore.authStore.auth) {
                /*
                if (rootStore.authStore.redirect) {
                    return Promise.reject(rootStore.authStore.redirect)
                }
                */
                return createRouterState('dashboard')
            }
        }
    },

    {
        name: 'dashboard',
        pattern: '/dashboard',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'sensorvalues',
        pattern: '/sensorvalues',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },

    {
        name: 'definitions',
        pattern: '/definitions',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'definitions.sensor',
        pattern: '/definitions/sensor',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'definitions.device',
        pattern: '/definitions/device',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'definitions.ruleChain',
        pattern: '/definitions/ruleChain',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'ruleEngine.ruleEditor',
        pattern: '/ruleEngine/ruleEditor/:id',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'definitions.deviceModel',
        pattern: '/definitions/deviceModel',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'definitions.deviceGroup',
        pattern: '/definitions/deviceGroup',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'definitions.user',
        pattern: '/definitions/user',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'definitions.role',
        pattern: '/definitions/role',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {
        name: 'alarm.alarm',
        pattern: '/definitions/alarm',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {

        name: 'sensorvalues.timeSeriesData',
        pattern: '/timeSeriesData/list',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        },

    },
    {

        name: 'sensorvalues.timeSeriesDataStream',
        pattern: '/timeSeriesData/stream',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        },

    },
    {

        name: 'alarm.alarmEvent',
        pattern: '/definitions/alarmEvent',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        },

    },
    {

        name: 'alarm.deviceEvent',
        pattern: '/definitions/deviceEvent',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        },

    },
    {

        name: 'reports',
        pattern: '/reports',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        },

    },
    {
        name: 'definitions.general',
        pattern: '/definitions/general',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }
    },
    {

        name: 'definitions.command',
        pattern: '/definitions/command',
        beforeEnter: async (fromState, toState, routerStore: { options: { rootStore: RootStore } }) => {
            const { rootStore } = routerStore.options

            return await globalBeforeEnter(fromState, toState, rootStore)
        }

    },
    {
        name: 'force',
        pattern: '/force',
    }

] as Route[]