/* tslint:disable */
/* eslint-disable */
/**
 * DeviceManagement Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    CommandToDeviceLogStatus,
    CommandToDeviceLogStatusFromJSON,
    CommandToDeviceLogStatusToJSON,
    ReportFormats,
    ReportFormatsFromJSON,
    ReportFormatsToJSON,
} from '../models';

export interface AlarmEventTableReportRequest {
    format?: ReportFormats;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    xTenantId?: string;
}

export interface AlarmTableReportRequest {
    format?: ReportFormats;
    xTenantId?: string;
}

export interface CommandToDeviceLogTableReportRequest {
    format?: ReportFormats;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    device?: string;
    status?: CommandToDeviceLogStatus;
    xTenantId?: string;
}

export interface DeviceEventTableReportRequest {
    format?: ReportFormats;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    xTenantId?: string;
}

export interface DeviceGroupTableReportRequest {
    format?: ReportFormats;
    xTenantId?: string;
}

export interface DeviceModelTableReportRequest {
    format?: ReportFormats;
    xTenantId?: string;
}

export interface DeviceReportRequest {
    format?: ReportFormats;
    deviceGroups?: Array<string>;
    deviceModel?: string;
    xTenantId?: string;
}

export interface DeviceTableReportRequest {
    format?: ReportFormats;
    deviceGroups?: Array<string>;
    deviceModel?: string;
    xTenantId?: string;
}

export interface RoleTableReportRequest {
    format?: ReportFormats;
    xTenantId?: string;
}

export interface RuleChainTableReportRequest {
    format?: ReportFormats;
    xTenantId?: string;
}

export interface TimeSeriesDataTableReportRequest {
    format?: ReportFormats;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    device?: string;
    deviceModel?: string;
    xTenantId?: string;
}

export interface UserTableReportRequest {
    format?: ReportFormats;
    roles?: Array<string>;
    xTenantId?: string;
}

/**
 * ReportExportApi - interface
 * 
 * @export
 * @interface ReportExportApiInterface
 */
export interface ReportExportApiInterface {
    /**
     * 
     * @param {ReportFormats} [format] 
     * @param {Date} [startDate] 
     * @param {Date} [endDate] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportExportApiInterface
     */
    alarmEventTableReportRaw(requestParameters: AlarmEventTableReportRequest): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    alarmEventTableReport(requestParameters: AlarmEventTableReportRequest): Promise<Blob>;

    /**
     * 
     * @param {ReportFormats} [format] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportExportApiInterface
     */
    alarmTableReportRaw(requestParameters: AlarmTableReportRequest): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    alarmTableReport(requestParameters: AlarmTableReportRequest): Promise<Blob>;

    /**
     * 
     * @param {ReportFormats} [format] 
     * @param {Date} [startDate] 
     * @param {Date} [endDate] 
     * @param {string} [device] 
     * @param {CommandToDeviceLogStatus} [status] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportExportApiInterface
     */
    commandToDeviceLogTableReportRaw(requestParameters: CommandToDeviceLogTableReportRequest): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    commandToDeviceLogTableReport(requestParameters: CommandToDeviceLogTableReportRequest): Promise<Blob>;

    /**
     * 
     * @param {ReportFormats} [format] 
     * @param {Date} [startDate] 
     * @param {Date} [endDate] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportExportApiInterface
     */
    deviceEventTableReportRaw(requestParameters: DeviceEventTableReportRequest): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    deviceEventTableReport(requestParameters: DeviceEventTableReportRequest): Promise<Blob>;

    /**
     * 
     * @param {ReportFormats} [format] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportExportApiInterface
     */
    deviceGroupTableReportRaw(requestParameters: DeviceGroupTableReportRequest): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    deviceGroupTableReport(requestParameters: DeviceGroupTableReportRequest): Promise<Blob>;

    /**
     * 
     * @param {ReportFormats} [format] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportExportApiInterface
     */
    deviceModelTableReportRaw(requestParameters: DeviceModelTableReportRequest): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    deviceModelTableReport(requestParameters: DeviceModelTableReportRequest): Promise<Blob>;

    /**
     * 
     * @param {ReportFormats} [format] 
     * @param {Array<string>} [deviceGroups] 
     * @param {string} [deviceModel] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportExportApiInterface
     */
    deviceReportRaw(requestParameters: DeviceReportRequest): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    deviceReport(requestParameters: DeviceReportRequest): Promise<Blob>;

    /**
     * 
     * @param {ReportFormats} [format] 
     * @param {Array<string>} [deviceGroups] 
     * @param {string} [deviceModel] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportExportApiInterface
     */
    deviceTableReportRaw(requestParameters: DeviceTableReportRequest): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    deviceTableReport(requestParameters: DeviceTableReportRequest): Promise<Blob>;

    /**
     * 
     * @param {ReportFormats} [format] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportExportApiInterface
     */
    roleTableReportRaw(requestParameters: RoleTableReportRequest): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    roleTableReport(requestParameters: RoleTableReportRequest): Promise<Blob>;

    /**
     * 
     * @param {ReportFormats} [format] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportExportApiInterface
     */
    ruleChainTableReportRaw(requestParameters: RuleChainTableReportRequest): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    ruleChainTableReport(requestParameters: RuleChainTableReportRequest): Promise<Blob>;

    /**
     * 
     * @param {ReportFormats} [format] 
     * @param {Date} [startDate] 
     * @param {Date} [endDate] 
     * @param {string} [device] 
     * @param {string} [deviceModel] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportExportApiInterface
     */
    timeSeriesDataTableReportRaw(requestParameters: TimeSeriesDataTableReportRequest): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    timeSeriesDataTableReport(requestParameters: TimeSeriesDataTableReportRequest): Promise<Blob>;

    /**
     * 
     * @param {ReportFormats} [format] 
     * @param {Array<string>} [roles] 
     * @param {string} [xTenantId] system admin tenant Id değiştirmesi için
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportExportApiInterface
     */
    userTableReportRaw(requestParameters: UserTableReportRequest): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    userTableReport(requestParameters: UserTableReportRequest): Promise<Blob>;

}

/**
 * 
 */
export class ReportExportApi extends runtime.BaseAPI implements ReportExportApiInterface {

    /**
     */
    async alarmEventTableReportRaw(requestParameters: AlarmEventTableReportRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/report/ReportExport/AlarmEventTableReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async alarmEventTableReport(requestParameters: AlarmEventTableReportRequest): Promise<Blob> {
        const response = await this.alarmEventTableReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async alarmTableReportRaw(requestParameters: AlarmTableReportRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/report/ReportExport/AlarmTableReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async alarmTableReport(requestParameters: AlarmTableReportRequest): Promise<Blob> {
        const response = await this.alarmTableReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async commandToDeviceLogTableReportRaw(requestParameters: CommandToDeviceLogTableReportRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.device !== undefined) {
            queryParameters['device'] = requestParameters.device;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/report/ReportExport/CommandToDeviceLogTableReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async commandToDeviceLogTableReport(requestParameters: CommandToDeviceLogTableReportRequest): Promise<Blob> {
        const response = await this.commandToDeviceLogTableReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deviceEventTableReportRaw(requestParameters: DeviceEventTableReportRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/report/ReportExport/DeviceEventTableReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async deviceEventTableReport(requestParameters: DeviceEventTableReportRequest): Promise<Blob> {
        const response = await this.deviceEventTableReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deviceGroupTableReportRaw(requestParameters: DeviceGroupTableReportRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/report/ReportExport/DeviceGroupTableReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async deviceGroupTableReport(requestParameters: DeviceGroupTableReportRequest): Promise<Blob> {
        const response = await this.deviceGroupTableReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deviceModelTableReportRaw(requestParameters: DeviceModelTableReportRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/report/ReportExport/DeviceModelTableReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async deviceModelTableReport(requestParameters: DeviceModelTableReportRequest): Promise<Blob> {
        const response = await this.deviceModelTableReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deviceReportRaw(requestParameters: DeviceReportRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.deviceGroups) {
            queryParameters['deviceGroups'] = requestParameters.deviceGroups;
        }

        if (requestParameters.deviceModel !== undefined) {
            queryParameters['deviceModel'] = requestParameters.deviceModel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/report/ReportExport/DeviceReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async deviceReport(requestParameters: DeviceReportRequest): Promise<Blob> {
        const response = await this.deviceReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deviceTableReportRaw(requestParameters: DeviceTableReportRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.deviceGroups) {
            queryParameters['deviceGroups'] = requestParameters.deviceGroups;
        }

        if (requestParameters.deviceModel !== undefined) {
            queryParameters['deviceModel'] = requestParameters.deviceModel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/report/ReportExport/DeviceTableReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async deviceTableReport(requestParameters: DeviceTableReportRequest): Promise<Blob> {
        const response = await this.deviceTableReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async roleTableReportRaw(requestParameters: RoleTableReportRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/report/ReportExport/RoleTableReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async roleTableReport(requestParameters: RoleTableReportRequest): Promise<Blob> {
        const response = await this.roleTableReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async ruleChainTableReportRaw(requestParameters: RuleChainTableReportRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/report/ReportExport/RuleChainTableReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async ruleChainTableReport(requestParameters: RuleChainTableReportRequest): Promise<Blob> {
        const response = await this.ruleChainTableReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async timeSeriesDataTableReportRaw(requestParameters: TimeSeriesDataTableReportRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.device !== undefined) {
            queryParameters['device'] = requestParameters.device;
        }

        if (requestParameters.deviceModel !== undefined) {
            queryParameters['deviceModel'] = requestParameters.deviceModel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/report/ReportExport/TimeSeriesDataTableReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async timeSeriesDataTableReport(requestParameters: TimeSeriesDataTableReportRequest): Promise<Blob> {
        const response = await this.timeSeriesDataTableReportRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async userTableReportRaw(requestParameters: UserTableReportRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.roles) {
            queryParameters['roles'] = requestParameters.roles;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/report/ReportExport/UserTableReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async userTableReport(requestParameters: UserTableReportRequest): Promise<Blob> {
        const response = await this.userTableReportRaw(requestParameters);
        return await response.value();
    }

}
