import { observable, action, computed, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { SensorDTO, SensorPostDTO, SensorUpdateDTO } from '../../api';



class SensorStore {
    rootStore: RootStore
    @observable isLoading = true
    @observable list: SensorDTO[] = []

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }

    @computed get Suggestions() {
        return this.list.map(d => ({ label: d.name, value: d.id }))
    }

    @action findSensor = (id: string) => {
        return this.list.find(o => o.id === id)
    }

    @action getList = async () => {
        this.isLoading = true
        this.list = [];
        this.list = (await this.rootStore.api.sensorApi.getSensors({}))
        this.isLoading = false
    }

    @action add = async (d: SensorPostDTO) => {
        let newD = (await this.rootStore.api.sensorApi.postSensor({ sensorPostDTO: d }))
        this.list.unshift(newD)
    }

    @action update = async (d: SensorUpdateDTO) => {
        (await this.rootStore.api.sensorApi.putSensor({ id: d.id!, sensorUpdateDTO: d }))
        if (true) {
            this.getList()
        }
    }

    @action delete = async (id: string) => {
        let a = this.list.find(t => t.id === id)
        //@ts-ignore
        this.list.remove(a)
        try {
            await this.rootStore.api.sensorApi.deleteSensor({ id: id })
        } catch {
            this.getList()
        }
    }
}

export default SensorStore