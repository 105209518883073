import { observable, makeObservable, action } from 'mobx';
import RootStore from "../RootStore"
import { CommandToDeviceLogDTO, CommandToDeviceLogStatus, ReportFormats } from '../../api';
import { Moment } from 'moment';
import moment from '../../utils/moment';
import { QueryResult } from '@material-table/core';
import saveAs from 'file-saver';
import i18n from '../../i18n';

class CommandStore {
    rootStore: RootStore
    @observable deviceFilter: string = ""
    @observable status: CommandToDeviceLogStatus | null = null
    @observable startDate: Moment | null = moment().add("day", -1).set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    @observable endDate: Moment | null = moment().add("day", 1).set({ hour: 12, minute: 0, second: 0, millisecond: 0 })

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }

    getList = async (query) => {


        let result = await this.rootStore.api.command.getCommandToDeviceLog({
            deviceId: this.deviceFilter === "" ? undefined : this.deviceFilter,
            status: this.status === null ? undefined : this.status,
            startDate: this.startDate === null ? undefined : this.startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
            endDate: this.endDate === null ? undefined : this.endDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
            pageNumber: query.page + 1,
            pageSize: query.pageSize
        })

        return {
            data: result.data,
            page: result.pageNumber! - 1,
            totalCount: result.totalRecords,
        } as QueryResult<CommandToDeviceLogDTO>

    }

    @action commandToDeviceLogTableReport(format: ReportFormats) {

        this.rootStore.api.reportApi.commandToDeviceLogTableReport({
            format: format,
            device: this.deviceFilter === "" ? undefined : this.deviceFilter,
            status: this.status === null ? undefined : this.status,
            startDate: this.startDate === null ? undefined : this.startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
            endDate: this.endDate === null ? undefined : this.endDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
        }).then((data) => {
            if (data) {
                saveAs(data, i18n.t("reports.tablo.commandDeviceLogEvent") + " " + moment().format("LLL") + (format === ReportFormats.Pdf ? ".pdf" : ".xlsx"))
            }
        })
    }

}

export default CommandStore