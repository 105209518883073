import { observable, action, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { ReportFormats } from '../../api';
import moment from '../../utils/moment';
import saveAs from 'file-saver'
import i18n from '../../i18n';

class ReportStore {
    rootStore: RootStore
    @observable deviceGroupFilter: string[] = []
    @observable deviceModelFilter: string = ""
    @observable reportLoading: boolean = false

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }

    @action deviceReport(reportType: string, format: ReportFormats) {

        this.rootStore.api.reportApi.deviceReport({
            format: format,
            deviceGroups: this.deviceGroupFilter,
            deviceModel: this.deviceModelFilter
        }).then((data) => {
            if (data) {
                saveAs(data, i18n.t("reports." + reportType) + " " + moment().format("LLL") + (format === ReportFormats.Pdf ? ".pdf" : ".xlsx"))
            }
            this.reportLoading = false

        })
    }
}

export default ReportStore