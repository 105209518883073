import { observable, action, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { ReportFormats, TimeSeriesDataDTO } from '../../api';
import { Moment } from 'moment';
import moment from '../../utils/moment';
import { QueryResult } from '@material-table/core';
import saveAs from 'file-saver'
import i18n from '../../i18n';
class TimeSeriesDataStore {
    rootStore: RootStore
    @observable deviceFilter: string = ""
    @observable deviceModelFilter: string = ""
    @observable startDate: Moment | null = moment().add("day", -1).set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    @observable endDate: Moment | null = moment().add("day", 1).set({ hour: 12, minute: 0, second: 0, millisecond: 0 })

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }

    getList = async (query) => {


        let result = await this.rootStore.api.timeSeriesDataApi.getTimeSeriesData({
            deviceId: this.deviceFilter === "" ? undefined : this.deviceFilter,
            startDate: this.startDate === null ? undefined : this.startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
            endDate: this.endDate === null ? undefined : this.endDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
            deviceModelId: this.deviceModelFilter === "" ? undefined : this.deviceModelFilter,
            pageNumber: query.page + 1,
            pageSize: query.pageSize
        })

        return {
            data: result.data,
            page: result.pageNumber! - 1,
            totalCount: result.totalRecords,
        } as QueryResult<TimeSeriesDataDTO>

    }

    @action add = async (d: TimeSeriesDataDTO) => {
        let newD = (await this.rootStore.api.timeSeriesDataApi.postTimeSeriesData({ timeSeriesDataDTO: d }))
    }

    @action timeSeriesDataTableReport(format: ReportFormats) {

        this.rootStore.api.reportApi.timeSeriesDataTableReport({
            format: format,
            device: this.deviceFilter === "" ? undefined : this.deviceFilter,
            startDate: this.startDate === null ? undefined : this.startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
            endDate: this.endDate === null ? undefined : this.endDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
            deviceModel: this.deviceModelFilter === "" ? undefined : this.deviceModelFilter,
        }).then((data) => {
            if (data) {
                saveAs(data, i18n.t("reports.tablo.timeSeriesData") + " " + moment().format("LLL") + (format === ReportFormats.Pdf ? ".pdf" : ".xlsx"))
            }
        })
    }

}

export default TimeSeriesDataStore