import { observer } from 'mobx-react-lite'
import React, { Suspense } from 'react'

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import loadable from '@loadable/component'


// Containers

import ErrorBoundary from './ErrorBoundary';
import Spinner from './components/Spinner/Spinner';
import StoreProvider from './StoreProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Helmet } from "react-helmet";
//const DevTools = lazy(() => import('mobx-react-devtools' /* webpackChunkName: 'mobx-react-devtools' */));
const AppContainer = loadable(() => import(/* webpackChunkName: "AppContainer" */ './containers/App'));


if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://cf7c64bea9f84dfe8050d8ee124154de@str.dev.lemonsoft.com.tr/9',
        release: process.env.REACT_APP_VERSION,
        environment: `${process.env.NODE_ENV}-${process.env.REACT_APP_SV ? process.env.REACT_APP_SV : "prod"}`,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        blacklistUrls: [
            /extensions\//i,
            /^chrome:\/\//i
        ]
    })
} else {
    localStorage.debug = 'msr:*';
}

Sentry.configureScope((scope) => {
    scope.setTag("page_locale", "tr");
});


const App = Sentry.withProfiler(
    observer(
        (props) => {
            return (
                <Suspense fallback={<Spinner />}>
                    <ErrorBoundary>
                        <StoreProvider>
                            <MuiPickersUtilsProvider utils={MomentUtils} locale="tr">
                                <Helmet titleTemplate="Iot Platform - %s" defaultTitle="Iot Platform">
                                    <meta name="language" content="Turkish" />
                                    <meta charSet="utf-8" />
                                    <meta name="description" content="Iot Platform" />
                                </Helmet>
                                <AppContainer />
                            </MuiPickersUtilsProvider>
                        </StoreProvider>

                    </ErrorBoundary>
                </Suspense>
            )
        }
    )
)

export default App