import { observable, action, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { ReportFormats, UserDTO, UserPostDTO, UserUpdateDTO } from '../../api';
import moment from 'moment';
import saveAs from 'file-saver'
import i18n from '../../i18n';
class UserStore {
    rootStore: RootStore
    @observable isLoading = true
    @observable list: UserDTO[] = []
    @observable roleFilter: string[] = []

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }

    @action findUser = (id: string) => {
        return this.list.find(o => o.id === id)
    }

    @action getList = async () => {
        this.isLoading = true
        this.list = [];
        this.list = await this.rootStore.api.userApi.getUsers({ roles: this.roleFilter.length === 0 ? undefined : this.roleFilter })
        this.isLoading = false
    }

    @action add = async (d: UserPostDTO) => {
        let newD = (await this.rootStore.api.userApi.postUser({ userPostDTO: d }))
        this.list.unshift(newD)
    }

    @action update = async (d: UserUpdateDTO) => {
        (await this.rootStore.api.userApi.putUser({ id: d.id!, userUpdateDTO: d }))
        if (true) {
            this.getList()
        }
    }

    @action delete = async (id: string) => {
        let a = this.list.find(t => t.id === id)
        //@ts-ignore
        this.list.remove(a)
        try {
            await this.rootStore.api.userApi.deleteUser({ id: id })
        } catch {
            this.getList()
        }
    }

    @action userTableReport(format: ReportFormats) {

        this.rootStore.api.reportApi.userTableReport({
            format: format,
            roles: this.roleFilter.length === 0 ? undefined : this.roleFilter
        }).then((data) => {
            if (data) {
                saveAs(data, i18n.t("reports.tablo.user") + " " + moment().format("LLL") + (format === ReportFormats.Pdf ? ".pdf" : ".xlsx"))
            }
        })
    }
}

export default UserStore