
export interface IPages {
    pathname: string,
    oneOfPermission?: string[],
    permission?: string,
    displayNav?: boolean,
    rental?: boolean,
    title: string | boolean,
    children?: IPages[]
}
export const pages: IPages[] = [
    {
        pathname: '404',
        displayNav: false,
        title: false,
    },
    {
        pathname: 'login',
        displayNav: false,
        title: false,
    },
    {
        pathname: 'force',
        displayNav: false,
        title: false,
    },
    {
        pathname: 'relogin',
        displayNav: false,
        title: false,
    },
    {
        pathname: 'ruleEngine.ruleEditor',
        title: 'ruleEngine.ruleEditor.title',
        displayNav: false,
    },

    {
        pathname: 'dashboard',
        title: "home",
    },
    {
        pathname: 'sensorvalues',
        title: "sensorvalues.title",

        children: [
            {
                pathname: 'sensorvalues.timeSeriesData',
                title: 'sensorvalues.db',
            },
            {
                pathname: 'sensorvalues.timeSeriesDataStream',
                title: 'sensorvalues.stream',
            }
        ]
    },
    {
        pathname: 'alarm',
        title: "alarm.title",

        children: [
            {
                pathname: 'alarm.alarmEvent',
                title: 'alarm.alarmEvent.title',
            },
            {
                pathname: 'alarm.deviceEvent',
                title: 'alarm.deviceEvent.title',
            },
            {
                pathname: 'alarm.alarm',
                title: 'alarm.ruleEngineAlarm.title',
            },
        ]
    },

    {
        pathname: 'definitions.device',
        title: 'definitions.device.title',
    },
    {
        pathname: 'definitions.command',
        title: 'definitions.commandToDevice.log.title',
    },
    {
        pathname: 'reports',
        title: 'reports.analytic',
    },
    {
        pathname: 'definitions',
        title: "definitions.title",

        children: [
            {
                pathname: 'definitions.deviceModel',
                title: 'definitions.deviceModel.title',
            },
            {
                pathname: 'definitions.deviceGroup',
                title: 'definitions.deviceGroup.title',
            },
            {
                pathname: 'definitions.user',
                title: 'definitions.user.title',
            },
            {
                pathname: 'definitions.role',
                title: 'definitions.role.title',
            },
            {
                pathname: 'definitions.ruleChain',
                title: 'definitions.ruleChain.title',
            },
            {
                pathname: 'definitions.general',
                title: 'definitions.general.title',
            }
        ],
    },
];