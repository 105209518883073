import { grey } from '@material-ui/core/colors';
import createTheme from '@material-ui/core/styles/createTheme';
import baseTheme from './base';


const darkTheme = createTheme({
    props: {

    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                ":root": {
                    "color-scheme": "dark",
                },
            },
        },
    },
    palette: {
        primary: {
            main: "#438eb9",
        },
        secondary: {
            main: '#995A00',
        },
        type: "dark",
        background: {
            default: '#121212',
            level1: grey[900],
            level2: "#333"
        },

    },
}, baseTheme)

export default darkTheme