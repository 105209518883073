import { observable, action, computed, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { DeviceModelDTO, DeviceModelPostDTO, DeviceModelUpdateDTO, ReportFormats } from '../../api';
import moment from 'moment';
import saveAs from 'file-saver'
import i18n from '../../i18n';
class DeviceModelStore {
    rootStore: RootStore
    @observable isLoading = true
    @observable list: DeviceModelDTO[] = []

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }

    @computed get Suggestions() {
        return this.list.map(d => ({ label: d.name, value: d.id }))
    }

    @action findDeviceModel = (id: string) => {
        return this.list.find(o => o.id === id)
    }

    @action getList = async () => {
        this.isLoading = true
        this.list = [];
        this.list = (await this.rootStore.api.deviceModelApi.getDeviceModels({}))
        this.isLoading = false
    }

    @action add = async (d: DeviceModelPostDTO) => {
        let newD = (await this.rootStore.api.deviceModelApi.postDeviceModel({ deviceModelPostDTO: d }))
        this.list.unshift(newD)
    }

    @action update = async (d: DeviceModelUpdateDTO) => {
        (await this.rootStore.api.deviceModelApi.putDeviceModel({ id: d.id!, deviceModelUpdateDTO: d }))
        if (true) {
            this.getList()
        }
    }

    @action delete = async (id: string) => {
        let a = this.list.find(t => t.id === id)
        //@ts-ignore
        this.list.remove(a)
        try {
            await this.rootStore.api.deviceModelApi.deleteDeviceModel({ id: id })
        } catch {
            this.getList()
        }
    }

    @action deviceModelTableReport(format: ReportFormats) {

        this.rootStore.api.reportApi.deviceModelTableReport({
            format: format
        }).then((data) => {
            if (data) {
                saveAs(data, i18n.t("reports.tablo.deviceModel") + " " + moment().format("LLL") + (format === ReportFormats.Pdf ? ".pdf" : ".xlsx"))
            }
        })
    }
}

export default DeviceModelStore