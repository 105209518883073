
import DeviceGroupStore from './data/DeviceGroupStore';
import DeviceStore from './data/DeviceStore';
import RuleChainStore from './data/RuleChainStore';
import SensorStore from './data/SensorStore';
import RootStore from './RootStore';
import DeviceModelStore from './data/DeviceModelStore';
import UserStore from './data/UserStore';
import RoleStore from './data/RoleStore';
import RuleEngineAlarmStore from './data/RuleEngineAlarmStore';
import TimeSeriesDataStore from './data/TimeSeriesDataStore';
import ReportStore from './data/ReportStore';
import TenantStore from './data/TenantStore';
import CommandStore from './data/CommandStore';
import ImportStore from './data/ImportStore';

class DataStore {
    rootStore: RootStore

    sensor: SensorStore
    device: DeviceStore
    deviceGroup: DeviceGroupStore
    ruleChain: RuleChainStore
    deviceModel: DeviceModelStore
    user: UserStore
    role: RoleStore
    alarm: RuleEngineAlarmStore
    timeSeriesData: TimeSeriesDataStore
    report: ReportStore
    tenant: TenantStore
    command: CommandStore
    import: ImportStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore

        this.sensor = new SensorStore(rootStore)
        this.device = new DeviceStore(rootStore)
        this.deviceGroup = new DeviceGroupStore(rootStore)
        this.ruleChain = new RuleChainStore(rootStore)
        this.deviceModel = new DeviceModelStore(rootStore)
        this.user = new UserStore(rootStore)
        this.role = new RoleStore(rootStore)
        this.alarm = new RuleEngineAlarmStore(rootStore)
        this.timeSeriesData = new TimeSeriesDataStore(rootStore)
        this.report = new ReportStore(rootStore)
        this.tenant = new TenantStore(rootStore)
        this.command = new CommandStore(rootStore)
        this.import = new ImportStore(rootStore)
    }
}


export default DataStore