import { observable, action, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import saveAs from 'file-saver'
import i18n from '../../i18n';

class ImportStore {
    rootStore: RootStore
    @observable isLoading = true

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }

    @action getDeviceExampleFile() {

        this.rootStore.api.importApi.getDeviceExampleFile({}).then((data) => {
            if (data) {
                saveAs(data, i18n.t("definitions.device.exampleExcel"))
            }
        })
    }

    @action importDeviceExcelFile = async (d: Blob) => {

        await this.rootStore.api.importApi.postDeviceExcelFile({
            inlineObject: { uploadedFile: d === null ? undefined : d }
        })
    }
}

export default ImportStore