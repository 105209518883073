import { observable, action, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { ReportFormats, RoleDTO, RolePostDTO, RoleUpdateDTO } from '../../api';
import moment from 'moment';
import saveAs from 'file-saver'
import i18n from '../../i18n';
class RoleStore {
    rootStore: RootStore
    @observable isLoading = true
    @observable list: RoleDTO[] = []
    @observable roleFilter: string[] = []

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }

    @action findRole = (id: string) => {
        return this.list.find(o => o.id === id)
    }

    @action getList = async () => {
        this.isLoading = true
        this.list = [];
        this.list = await this.rootStore.api.userApi.getRoles({})
        this.isLoading = false
    }

    @action add = async (d: RolePostDTO) => {
        let newD = (await this.rootStore.api.userApi.postRole({ rolePostDTO: d }))
        this.list.unshift(newD)
    }

    @action update = async (d: RoleUpdateDTO) => {
        (await this.rootStore.api.userApi.putRole({ id: d.id!, roleUpdateDTO: d }))
        if (true) {
            this.getList()
        }
    }

    @action delete = async (id: string) => {
        let a = this.list.find(t => t.id === id)
        //@ts-ignore
        this.list.remove(a)
        try {
            await this.rootStore.api.userApi.deleteRole({ id: id })
        } catch {
            this.getList()
        }
    }

    @action rolTableReport(format: ReportFormats) {

        this.rootStore.api.reportApi.roleTableReport({
            format: format
        }).then((data) => {
            if (data) {
                saveAs(data, i18n.t("reports.tablo.role") + " " + moment().format("LLL") + (format === ReportFormats.Pdf ? ".pdf" : ".xlsx"))
            }
        })
    }
}

export default RoleStore