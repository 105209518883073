import { observable, action, computed, makeObservable } from 'mobx';
import RootStore from "../RootStore"
import { DeviceDTO, DevicePostDTO, DeviceUpdateDTO, ReportFormats } from '../../api';
import moment from 'moment';
import saveAs from 'file-saver'
import i18n from '../../i18n';
class DeviceStore {
    rootStore: RootStore
    @observable isLoading = true
    @observable list: DeviceDTO[] = []
    @observable deviceGroupFilter: string[] = []
    @observable deviceModelFilter: string = ""
    @observable deviceCount: string = ''

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }

    @computed get Suggestions() {
        return this.list.map(d => ({ label: d.name, value: d.id }))
    }

    @action findDevice = (id: string) => {
        return this.list.find(o => o.id === id)
    }

    @action getList = async () => {
        this.isLoading = true
        this.list = [];

        this.list = await this.rootStore.api.deviceApi.getDevices({
            deviceGroups: this.deviceGroupFilter.length === 0 ? undefined : this.deviceGroupFilter,
            deviceModel: this.deviceModelFilter === "" ? undefined : this.deviceModelFilter,
        })
        this.isLoading = false
    }

    @action add = async (d: DevicePostDTO) => {
        let newD = (await this.rootStore.api.deviceApi.postDevice({ devicePostDTO: d }))
        this.list.unshift(newD)
    }

    @action update = async (d: DeviceUpdateDTO) => {
        (await this.rootStore.api.deviceApi.putDevice({ id: d.id!, deviceUpdateDTO: d }))
        if (true) {
            this.getList()
        }
    }

    @action delete = async (id: string) => {
        let a = this.list.find(t => t.id === id)
        //@ts-ignore
        this.list.remove(a)
        try {
            await this.rootStore.api.deviceApi.deleteDevice({ id: id })
        } catch {
            this.getList()
        }
    }
    @action getDeviceCount = async () => {
        this.deviceCount = (await this.rootStore.api.deviceApi.getDeviceCount({})).toString()
    }

    @action deviceTableReport(format: ReportFormats) {

        this.rootStore.api.reportApi.deviceTableReport({
            format: format,
            deviceGroups: this.deviceGroupFilter.length === 0 ? undefined : this.deviceGroupFilter,
            deviceModel: this.deviceModelFilter === "" ? undefined : this.deviceModelFilter,
        }).then((data) => {
            if (data) {
                saveAs(data, i18n.t("reports.tablo.device") + " " + moment().format("LLL") + (format === ReportFormats.Pdf ? ".pdf" : ".xlsx"))
            }
        })
    }
}

export default DeviceStore